import React, {useContext, useEffect, useState} from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button
} from '@mui/material'
import {tableCellClasses} from '@mui/material/TableCell'
import {buttonClasses} from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import {completeOfferBonus} from "../../../../../api/Challenge"
import CircularProgress from '@mui/material/CircularProgress';
import IMAlerts from '../../../../common/IMAlerts';
import {SupportModalContext} from "./SupportModalContext";


const OfferBonusRow = (props) => {
  const [bonus, setBonus] = useState(props.bonus)
  const [bonusButtonState, setBonusButtonState] = useState("enabled")
  const [bonusButtonDisabled, setBonusButtonDisabled] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const {group} = useContext(SupportModalContext)

  const highlightColor = '#f6f1bd'

  const baseButtonStyles = {
    width: "80%",
    fontSize: "12px",
    padding: 0,
    margin: 0,
    "&.Mui-disabled": {
      backgroundColor: '#BDBDBD',
      color: "white"
    }
  }

  const BonusTableCell = styled(TableCell)(({})=>({
    [`&.${tableCellClasses.root}`]: {
      padding: "5px 10px"
    },
  }))

  const CompleteButton = styled(Button)(({})=>({
    [`&.${buttonClasses.root}`]: baseButtonStyles,
  }))

  function submitCompleteOfferBonus(){
    setBonusButtonState('working')
    setBonusButtonDisabled(true)
    completeOfferBonus(bonus, group).then((res) =>{
      console.log(res)
      if(res.status === 200 && !res.data.error){
        bonusToCapped()
        setBonusButtonDisabled(false)
        setBonusButtonState('enabled')
      } else {
        setBonusButtonDisabled(false)
        setBonusButtonState('enabled')
        setAlertOpen(true)
      }
    })
  }

  function rowStyles(){
    let backgroundColor = "inherit"
    if(bonus.highlighted){backgroundColor = highlightColor}
    return {
      backgroundColor: backgroundColor,
    }
  }

  function bonusToCapped(){
    const originBonus = bonus
    originBonus.showButton = false
    originBonus.capped = true
    setBonus(originBonus)
  }

  function buttonText(){
    if(bonusButtonState === "enabled"){return "COMPLETE"}
    if(bonusButtonState === "working"){
      return <>
        <CircularProgress
          size={14}
          style={{marginRight: "4px"}}
          color="inherit"
        />
        COMPLETING
      </>
    }
  }

  function cappedDisplay(){
    if(bonus.showButton && !bonus.capped){
      return <CompleteButton
              variant="contained"
              onClick={submitCompleteOfferBonus}
              disabled={bonusButtonDisabled}
            >
              {buttonText()}
            </CompleteButton>
    }
    if(bonus.capped && !bonus.showButton){return <span>Capped</span>}
    if (!bonus.capped && !bonus.showButton){return <span>Incomplete</span>}
  }

  function typeDisplay(){
    if(bonus.icon === "dartboard"){
      return <span role="img" aria-label="dartboard" style={{fontSize: 15, textAlign: "center", marginLeft: "5px"}}>🎯</span>
    } else {
      return ""
    }
  }

  function closeAlert(){
    setAlertOpen(false)
  }

  return(
    <>
      { alertOpen &&
        <IMAlerts
          severity={'error'}
          title={"Failed to Complete Offer Bonus"}
          message={`Could not complete ${bonus.title}.`}
          onClose={closeAlert}
        />
      }
      <TableRow style={rowStyles()}>
        <BonusTableCell>{bonus.title}</BonusTableCell>
        <BonusTableCell align="center">{typeDisplay()}</BonusTableCell>
        <BonusTableCell align="center">{bonus.points}</BonusTableCell>
        <BonusTableCell align="center">{cappedDisplay()}</BonusTableCell>
      </TableRow>
    </>
  )
}

export default OfferBonusRow