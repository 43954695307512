import React, {useContext, useState} from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import {tableCellClasses} from '@mui/material/TableCell'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableHead from "@mui/material/TableHead";
import {SupportModalContext} from "./SupportModalContext";
import OfferBonusRow from "./OfferBonusRow";

const OfferBonusTable = (props) => {
  const {group} = useContext(SupportModalContext)
  const offerBonuses = group.offerBonuses

  const HeaderTableCell = styled(TableCell)(({})=>({
    [`&.${tableCellClasses.root}`]: {
      fontWeight: 'bold',
      textAlign: "center",
      padding: "12px 10px",
    },
  }))

  console.log('group in table', group)

  return(
    <Table>
      <TableHead>
        <TableRow>
          <HeaderTableCell align="center">Title</HeaderTableCell>
          <HeaderTableCell align="center">Type</HeaderTableCell>
          <HeaderTableCell align="center">Points</HeaderTableCell>
          <HeaderTableCell align="center">Actions</HeaderTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {offerBonuses.map((bonus) => (
          <OfferBonusRow
            bonus={bonus}
          />
        ))}
      </TableBody>


    </Table>
  )
}

export default OfferBonusTable