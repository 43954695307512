import React, {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Link} from "@material-ui/core";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PriceChangeIcon from '@mui/icons-material/PriceChange';

import {SupportModalContext} from "./SupportModalContext";
import IapValueForm from "./IapValueForm";
import OfferBonusTable from "./OfferBonusTable";

export default function BaseSupportModal(props) {
  const {modalOpen, setModalOpen, group, setGroup} = useContext(SupportModalContext)
  const [iapTotalSpend, setIapTotalSpend] = useState(group.iapTotalSpend)


  function close(){
    setModalOpen(false)
  }

  const theme = createTheme({
    palette: {
      secondary: {
        main: '#B0BEC5',
      },
    },
  });

  const dialogTitleStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }

  const goalsTitleContainerStyles = {
    display: "flex",
    justifyContent: 'space-between',
  }

  const engageInfoStyles={
    backgroundColor: '#BDBDBD',
    display: "flex",
    color: 'white',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '20px 0',
    borderRadius: '5px',
    padding: '0px 15px',
    fontSize: '15px',
  }

  const iapInfoStyles = {
    ...engageInfoStyles,
    backgroundColor: '#0288D1',
  }

  return(
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper' }}>
          <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 'fit-content', minWidth: "650px" } }}
            maxWidth="sm"
            open={modalOpen}
            onClose={close}
          >
            <DialogTitle style={dialogTitleStyles}>
              <h3 style={{margin: 0}}>Report Progress</h3>
              <CloseIcon onClick={close} sx={{'&:hover': {cursor: 'pointer'} }}/>
            </DialogTitle>

            <DialogContent dividers>
              <div style={goalsTitleContainerStyles}>
                <h3 style={{margin: 0}}>Goals</h3>
                { group.engageLink &&
                  <Button>
                    <OpenInNewIcon
                      style={{height: "20px"}}
                    />
                    <Link
                      href={group.engageLink}
                      underline="none"
                      style={{fontWeight: "bold", color: "#1976d2"}}
                      target={"_blank"}
                    >OPEN IN ENGAGE</Link>
                  </Button>
                }
              </div>

              <OfferBonusTable/>
              <div style={engageInfoStyles}>
                <InfoOutlinedIcon style={{color: 'white'}}/>
                <p style={{marginLeft: '10px'}}>To see all bonuses, open in Engage</p>
              </div>

              <div style={{width: '100%', borderTop: '1px solid rgb(213 213 213)'}}/>

              <div style={iapInfoStyles}>
                <PriceChangeIcon style={{color: 'white'}}/>
                <p style={{marginLeft: '10px'}}>Current Purchases: {iapTotalSpend} / {group.iapRewardCap}</p>
              </div>

              <IapValueForm
                iapTotalSpend={iapTotalSpend}
                setIapTotalSpend={setIapTotalSpend}
              />

            </DialogContent>

        </Dialog>
      </Box>
    </ThemeProvider>
  )
}