import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Switch from '@mui/material/Switch';
import Chip from '@mui/material/Chip';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';

const CONFIRM_ACTION_LABELS = [
  'I confirm the new Redeeming Points']

function TabPanel(props) {
  const { children, tabView, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabView !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabView === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
//
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  tabView: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function SyncPrizes({prizesCatalogSyncingInProcess, prizesMobileSyncingInProcess}) {
  return (
    <FormControl>
    <Stack  spacing={2}>
      <Chip
       variant="outlined"
        onClick={prizesMobileSyncingInProcess}
        icon={<MobileScreenShareIcon  />}
        label={"SYNC TO MOBILE"}
       />
       <FormHelperText id="MobileSyncing-text">Sync current Standards denominations to mobile. (DynamoDB)</FormHelperText>
       <Chip
         variant="outlined"
         onClick={prizesCatalogSyncingInProcess}
         icon={<ImportContactsIcon  />}
         label={"SYNC PRIZES CATALOG"}
        />
        <FormHelperText id="CatalogSyncing-text">Sync Tango catalog to our system and Sync Standards denominations to mobile.(PG+DynamoDB)</FormHelperText>
    </Stack>
    </FormControl>
  )
}
function RedeemPointsPerDollar({onFieldChange, redeemPointsPerDollar }) {
  const [ val, setVal ] = useState(redeemPointsPerDollar)
  const onChangeNumberField = (newValue) => {
    let value = newValue ? Number(newValue.target.value) : 0
    if (isNaN(value) || value < 0 || value === 0) {
      value = redeemPointsPerDollar
    }

    setVal(value)
    onFieldChange({ redeemPointsPerDollar: value })

  }


  return (
    <Stack spacing={4} >
      <Typography sx={{weight: 700, fontSize: '16px', lineHeight: '24px'}}>
        Redeeming points per dollar.
      </Typography>
      <Box sx={{ background: "#e3f2fd",
        height: '90px',
        display: 'none',
        alignItems: 'start',
        }}>
        <Box sx={{marginTop: '19px'}}>
        <ErrorOutlineIcon sx={{margin: '8px', color: "#0288D1"}}/>
        </Box>
        <Box >
          <Typography sx={{fontSize: '14px', fontWeight: 400, margin: 2, display: 'flex'
            }} >
              <Box sx={{height: '10px', display: 'flex', marginTop: '12px'}}>
                Once confirmed, all cards earn points would be synced accordingly
              </Box>
           </Typography>
        </Box>
      </Box>
      <Box>


      <FormControl disabled variant="standard" sx={{ m: 1, mt: 3, width: '25ch' }}>
         <Input
           id="standard-adornment-redeem-points-per-dollar"
           endAdornment={<InputAdornment position="end">points/$</InputAdornment>}
           aria-describedby="standard-weight-helper-text"
           inputProps={{
             'aria-label': 'redeem-points-per-dollar',
           }}
           value={val}
           onChange={onChangeNumberField}
         />
         <FormHelperText id="standard-weight-helper-text">Points Per Dollar</FormHelperText>
       </FormControl>

      </Box>

    </Stack>
  )

}


export default function PrizesConfigsContent({ onFieldChange, redeemPointsPerDollar, prizesMobileSyncingInProcess, prizesCatalogSyncingInProcess }) {

  const [ tabView, setTabView ] = useState(0)
  const [ resetCheck, setResetCehck ] = useState(true)

  const handleChangeTab = (event, newValue) => {
    setTabView(newValue);
    setResetCehck(true)
    // handleChangeTabProp()
  };

  const onCheckConfirm = (event) => {
    setResetCehck(!resetCheck)
    onFieldChange({ confirm: event.target.checked })
  }

  return (
    <Box sx={{ }}>
      <Box sx={{ width: '100%' }}>
       <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
         <Tabs value={tabView} onChange={handleChangeTab} aria-label="boost-red-dialog">
           <Tab label="Points" {...a11yProps(0)} />
           <Tab label="Sync Prizes" {...a11yProps(1)} />

         </Tabs>
       </Box>


       <TabPanel tabView={tabView} index={0}>
         <RedeemPointsPerDollar
          onFieldChange={onFieldChange}
          redeemPointsPerDollar={redeemPointsPerDollar}/>
       </TabPanel>
       <TabPanel tabView={tabView} index={1}>
         <SyncPrizes
           prizesMobileSyncingInProcess={prizesMobileSyncingInProcess}
           prizesCatalogSyncingInProcess={prizesCatalogSyncingInProcess}
           />
       </TabPanel>


     </Box>



    </Box>
  )

}
