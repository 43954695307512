import React, { useContext, useState, useEffect } from "react";
import ChallengeStatusFilter from "./Filters/ChallengeStatusFilter";
import { ChallengesContext } from "./ChallengesContext";
import Button from "@mui/material/Button";
import RefreshIcon from '@mui/icons-material/Refresh';

const ChallengeFilters = (props) => {
  const {shownChallengeGroups, challengeGroups, setShownChallengeGroups } = useContext(ChallengesContext);
  const [selectedStatus, setSelectedStatus] = useState('all')
  const [filteredChallengeGroups, setFilteredChallengeGroups] = useState([])

  const filterStyles = {
    display: 'flex'
  }

  useEffect(()=>{
    let groups = filterChallengeGroups()
    setShownChallengeGroups(groups)
  },[selectedStatus, filteredChallengeGroups])

  useEffect(()=>{
    setFilteredChallengeGroups(shownChallengeGroups)
  },[])

  function filterChallengeGroups(){
    const stateFiltered = []

    for (let i = 0; i < filteredChallengeGroups.length; i++) {
      let group = filteredChallengeGroups[i]
      if( group.status === selectedStatus || selectedStatus === 'all'){
        stateFiltered.push(group)
      }
    }
    return stateFiltered
  }

  function handleRefreshClick(){
    props.refreshAction()
  }

  return(
    <div style={filterStyles}>
      <ChallengeStatusFilter
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />
      <Button
        variant={"contained"}
        onClick={handleRefreshClick}
      ><RefreshIcon/></Button>
    </div>
  )
}

export default ChallengeFilters