import auFlag from '../../assets/AU.png';
import usFlag from '../../assets/US.png';
import caFlag from '../../assets/CA.png';
import gbFlag from '../../assets/GB.png';

export const COUNTRY_MAP = {
  'US': { icon: usFlag,  currency: 'USD'},
  'CA': { icon: caFlag,  currency: 'CAD'},
  'GB': { icon: gbFlag,  currency: 'GBP'},
  'UK': { icon: gbFlag,  currency: 'GBP'},
  'AU': { icon: auFlag,  currency: 'AUD'},

}
