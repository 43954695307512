import React, { useEffect, useState } from 'react';
import Tooltip from "@mui/material/Tooltip";


const FormattedDatesDisplay = (props) => {
  const epoch = props.epoch || 0
  const timezone = props.timezone || "America/New_York"
  const rootLabel = props.rootLabel || ""
  const showSecondaryDate = props.showSecondaryDate

  // Optional Labeling
  const label = props.label || ""
  const labelEpoch = props.labelEpoch ? props.labelEpoch : undefined

  // Injected Child Element
  const childElement = props.child || undefined

  const TooltipDate = (props) =>{
    const givenEpoch = props.epoch
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return(
      <span>{formatDate(givenEpoch, localTimezone)}</span>
    )
  }

  function formatDate(givenEpoch, givenTimezone){
    if(isNaN(givenEpoch)){return}

    try {
      let date = new Date(givenEpoch*1000)
      return date.toLocaleString("en-US", { timeZone: givenTimezone, hour12: true, timeZoneName: 'short' });
    } catch(err) {
      console.error(err)
      return new Date(givenEpoch*1000).toGMTString()
    }
  }

  return(
    <>
      <Tooltip title={
        <TooltipDate
          epoch={epoch}
        />
      }>
        <p style={{margin: 0, padding: 0}}>
          {rootLabel}{formatDate(epoch, timezone)}
          { childElement &&
            childElement
          }
        </p>
      </Tooltip>
      { label && labelEpoch && showSecondaryDate &&
        <Tooltip title={
          <TooltipDate
            epoch={labelEpoch}
          />
        }>
          <small><b>{label}</b> {formatDate(labelEpoch, timezone)}</small>
        </Tooltip>
      }
    </>


  )
}

export default FormattedDatesDisplay