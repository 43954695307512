import { getHeaders,  baseUrl } from './utils';

export async function completeOfferBonus(bonus, group){
  const postData = {
    data: {
      offer_bonus_id: bonus.id,
      installed_app_id: group.installedAppId,
      challenge_id: group.challengeId
    }
  }

  try {
    const response  = await fetch(`${baseUrl}/challenge/complete_bonus`,{
      method: "POST",
      credentials: 'include',
      headers: getHeaders(),
      body: JSON.stringify(postData)
    })

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { status: 401 };
  }
}

export async function addIapValue(group, amount){
  const postData = {
    data: {
      amount: amount,
      installed_app_id: group.installedAppId,
      challenge_id: group.challengeId
    }
  }

  try {
    const response  = await fetch(`${baseUrl}/challenge/add_iap_value`,{
      method: "POST",
      credentials: 'include',
      headers: getHeaders(),
      body: JSON.stringify(postData)
    })

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { status: 401 };
  }
}