import React, { useEffect, useState } from 'react';
import Tooltip from "@mui/material/Tooltip";
import FormattedDatesDisplay from './Shared/FormattedDatesDisplay'
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';

const SupportActionInfo = (props) => {
  const activity = props.activity
  const goalData = activity.supportActionData.goal || []
  const iapData = activity.supportActionData.iap || []

  const pStyles = {
    margin: 0,
    padding: 0
  }

  const goalDisplay = (data, index) => {
    return(
      <Box sx={{marginBottom: "8px"}} key={`spdg-${index}`}>
        <FormattedDatesDisplay
          epoch={data.date}
          timezone={activity.timezone}
          rootLabel={<b>Date: </b>}
        />
        <p style={pStyles}><b>Agent:</b> {data.agent}</p>
        <p style={pStyles}><b>Event Name:</b> {data.eventName} ({data.source})</p>
        <Divider/>
      </Box>
    )
  }

  const iapDisplay = (data, index) => {
    return(
      <Box sx={{marginBottom: "8px"}} key={`spdi-${index}`}>
        <FormattedDatesDisplay
          epoch={data.date}
          timezone={activity.timezone}
          rootLabel={<b>Date: </b>}
        />
        <p style={pStyles}><b>Agent:</b> {data.agent}</p>
        <p style={pStyles}><b>IAP Amount:</b> {data.amount} ({data.source})</p>
        <Divider/>
      </Box>
    )
  }

  return(
    <>
      {
        activity.goalType === "goal" && goalData.map((data, index) => (
          goalDisplay(data, index)
        ))
      }
      {
        activity.goalType === "iap_purchase" && iapData.map((data, index) => (
          iapDisplay(data, index)
        ))
      }
    </>
  )
}

export default SupportActionInfo